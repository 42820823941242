/*
Template Name: Admin Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: js
*/
jQuery(function () {
    "use strict";

    jQuery(document).on('click', '.mega-dropdown', function (e) {
        e.stopPropagation()
    });
    // ==============================================================
    // This is for the top header part and sidebar part
    // ==============================================================
    var set = function () {
        var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
        var topOffset = 0;
        if (width < 5170) {
            jQuery("body").addClass("mini-sidebar");
            jQuery(".sidebartoggler i").addClass("ti-menu");
        } else {
            jQuery("body").removeClass("mini-sidebar");
        }
        if (width < 767) {
            //jQuery('.scroll-sidebar').perfectScrollbar();
            jQuery('.navbar-brand span').hide();
        } else {
            //jQuery('.scroll-sidebar').perfectScrollbar('destroy').removeClass('ps');
            jQuery('.navbar-brand span').show();
        }
        var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
        height = height - topOffset;
        if (height < 1) height = 1;
        if (height > topOffset) {
            jQuery(".page-wrapper").css("min-height", (height) + "px");
        }

    };
    jQuery(window).ready(set);
    jQuery(window).on("resize", set);

    // ==============================================================
    // Theme options
    // ==============================================================
    jQuery(".sidebartoggler").on('click', function () {
        if (jQuery("body").hasClass("mini-sidebar")) {
            jQuery("body").trigger("resize");
            jQuery("body").removeClass("mini-sidebar");
            jQuery('.navbar-brand span').show();

        } else {
            jQuery("body").trigger("resize");
            jQuery("body").addClass("mini-sidebar");
            jQuery('.navbar-brand span').hide();

        }
    });

    // ==============================================================
    // Right sidebar options
    // ==============================================================
    jQuery(".right-side-toggle").on('click', function () {
        jQuery(".right-sidebar").slideDown(50);
        jQuery(".right-sidebar").toggleClass("shw-rside");
    });
    // ==============================================================
    // This is for the floating labels
    // ==============================================================
    jQuery('.floating-labels .form-control').on('focus blur', function (e) {
        jQuery(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');

    // ==============================================================
    //tooltip
    // ==============================================================
    jQuery(function () {
        jQuery('[data-toggle="tooltip"]').tooltip()
    })
    // ==============================================================
    //Popover
    // ==============================================================
    jQuery(function () {
        jQuery('[data-toggle="popover"]').popover()
    })
    // ==============================================================
    // Sidebarmenu
    // ==============================================================
    jQuery(function () {
        jQuery('#sidebarnav').AdminMenu();
    });

    jQuery(window).scroll(function () {
        if (jQuery(window).scrollTop() >= 200) {
            jQuery('body').addClass('fix-sidebar');
            jQuery('.left-sidebar').addClass('animated slideInDown');
        } else {
            jQuery('body').removeClass('fix-sidebar');
            jQuery('.left-sidebar').removeClass('animated slideInDown');
        }
    });

    // ==============================================================
    // Perfact scrollbar
    // ==============================================================
    //jQuery('.right-side-panel, .message-center, .right-sidebar').perfectScrollbar();

    // ==============================================================
    // Resize all elements
    // ==============================================================
    jQuery("body").trigger("resize");
    // ==============================================================
    // To do list
    // ==============================================================
    jQuery(".list-task li label").on('click', function () {
        jQuery(this).toggleClass("task-done");
    });



    // ==============================================================
    // Collapsable cards
    // ==============================================================
    jQuery('a[data-action="collapse"]').on('click', function (e) {
        e.preventDefault();
        jQuery(this).closest('.card').find('[data-action="collapse"] i').toggleClass('ti-minus ti-plus');
        jQuery(this).closest('.card').children('.card-body').collapse('toggle');

    });
    // Toggle fullscreen
    jQuery('a[data-action="expand"]').on('click', function (e) {
        e.preventDefault();
        jQuery(this).closest('.card').find('[data-action="expand"] i').toggleClass('mdi-arrow-expand mdi-arrow-compress');
        jQuery(this).closest('.card').toggleClass('card-fullscreen');
    });

    // Close Card
    jQuery('a[data-action="close"]').on('click', function () {
        jQuery(this).closest('.card').removeClass().slideUp('fast');
    });

});
